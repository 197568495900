const API = {
  TOKEN_HEADER_KEY: 'Authorization',
  BEARER: 'Bearer',
  ACCESS_KEY: 'at_park', 
  SEARCH_DEBOUNCE: 500,
  // token expiration is 3600 for external idp
  // leaving 300 seconds for refresh
  TOKEN_LIFETIME_BEFORE_REFRESH: 3300, // Refresh token if will expire in seconds
};

export default API;
